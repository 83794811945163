





























































































































































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import { IGroupUpdate } from "@/interfaces";
import {
  dispatchGetGroup,
  dispatchUpdateGroup,
  dispatchGetGroupsByFile,
  dispatchDeleteGroup,
  dispatchGetScenes,
  dispatchDeleteScene,
  dispatchUpdateActiceScene,
  dispatchGetBuildings,
  dispatchGetFilesByProject,
} from "@/store/main/actions";
import {
  readGroup,
  readGroups,
  readScenes,
  readBuildings,
  readUploadedFiles,
} from "@/store/main/getters";

@Component
export default class Edit extends Vue {
  [x: string]: any;
  public loading = true;
  public valid = false;
  public childGroups = [];
  public parentId: number | undefined;
  public oldParentId: number | undefined;
  public dialog = false;
  public sceneDialog = false;
  public name = "";
  public id = 0;
  public building_id: number | undefined;
  public floor_id:number | undefined;
  public project_id:number | undefined;

  public pagination = {
    ascending: true,
    sortBy: "name",
    rowsPerPage: 25,
    page: 1,
  };

  public headers = [
    {
      text: "Name",
      sortable: true,
      value: "name",
      align: "left",
    },
    {
      text: "Color",
      sortable: true,
      value: "light",
      align: "left",
    },
    {
      text: "Intensity",
      sortable: true,
      value: "intensity",
      align: "left",
    },
    {
      text: "X Color",
      sortable: true,
      value: "x_color",
      align: "left",
    },
    {
      text: "Y Color",
      sortable: true,
      value: "y_color",
      align: "left",
    },
    {
      text: "Color Type",
      sortable: true,
      value: "color_type",
      align: "left",
    },
    {
      text: "Activated Scene",
      value: "id",
      align: "center",
      sortable: false,
    },
    {
      text: "Actions",
      value: "id",
      align: "center",
      sortable: false,
    },
  ];

  public group: IGroupUpdate = {
    name: "",
    parent_id: this.parentId,
    old_parent_id: this.oldParentId,
    color_type: "cct",
    x_color: 0,
    y_color: 0,
    light: 0,
    intensity: 0,
    scene_status: false,
    scene_id: undefined,
  };

  get groupId() {
    return Number(this.$router.currentRoute.params.id);
  }

  public showListfloors(floors, building_id) {
    const listObjFloors = floors.filter(
      (floor) => floor.building_id == building_id
    );
    return listObjFloors;
  }

  // get projectId() {
  //   return Number(this.$router.currentRoute.params.id);
  // }
  get buildings() {
    return readBuildings(this.$store);
  }
  get files() {
    return readUploadedFiles(this.$store);
  }
  get groups() {
    let groups = this.allGroups;
    let results = [];
    this.oldParentId = undefined;
    this.parentId = undefined;

    groups.forEach((group) => {
      if (group!.group_ids.includes(this.groupId as never)) {
        this.oldParentId = group.id;
        this.parentId = group.id;
      }
      if (
        group.id != this.groupId &&
        !this.groupDetail!.group_ids.includes(group.id as never)
      ) {
        results.push(group as never);
      }
    });
    return results;
  }

  get allGroups() {
    return readGroups(this.$store);
  }

  get scenes() {
    return readScenes(this.$store);
  }

  get groupDetail() {
    const group = readGroup(this.$store);
    if (group != null) {
      this.building_id = group.building_id;
      this.file_id = group.file_id;
      this.project_id = group.project_id;
    }

    return readGroup(this.$store);
  }

  public reset() {
    this.group = <IGroupUpdate>{ ...readGroup(this.$store) };
    this.$validator.reset();
  }

  public cancel() {
    this.$router.back();
  }

  public async changeScene(id: any) {
    this.group.scene_id = id;
    await dispatchUpdateActiceScene(this.$store, {
      groupId: this.groupId,
      sceneId: this.group.scene_id,
    });
    await dispatchGetScenes(this.$store, this.groupId);
    this.group = <IGroupUpdate>{ ...readGroup(this.$store) };
  }

  public async mounted() {
    await dispatchGetGroup(this.$store, this.groupId);
    this.group = <IGroupUpdate>{ ...readGroup(this.$store) };
    if (this.groupDetail && this.groupDetail!.file_id) {
      await dispatchGetBuildings(this.$store, this.project_id as number);
      await dispatchGetFilesByProject(this.$store, this.project_id as number);
      await dispatchGetGroupsByFile(this.$store, this.file_id as number);
      await dispatchGetScenes(this.$store, this.groupId);
    }
    this.loading = false;
  }

  public async submit() {
    if (await this.$validator.validateAll()) {
      this.group.parent_id = this.parentId;
      this.group.old_parent_id = this.oldParentId;

      delete this.group.scene_id;
      await dispatchUpdateGroup(this.$store, {
        groupId: this.groupId,
        data: this.group,
      });
      await dispatchGetScenes(this.$store, this.groupId);
      await dispatchGetGroupsByFile(
        this.$store,
        this.groupDetail!.file_id as number
      );

      this.group = <IGroupUpdate>{ ...readGroup(this.$store) };
      // this.$router.back();
    }
  }

  public async confirm() {
    await dispatchDeleteGroup(this.$store, this.groupId);
    this.$router.back();
  }

  public async sceneConfirm() {
    this.sceneDialog = false;
    await dispatchDeleteScene(this.$store, this.id);
    await dispatchGetScenes(this.$store, this.groupId);
  }
}
